import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import _tp24 from 'tp24';

export const MOBILE_WIDTH = 576;
export const TABLET_WIDTH = 768;
export const DESKTOP_WIDTH = 992;
export const XLARGE_WIDTH = 1200;
export const XXLARGE_WIDTH = 1920;
export const countryCode = pathOr('GR', ['data', 'config', 'iso_country_code'], _tp24);
export const brand = pathOr('airtickets', ['data', 'config', 'brand'], _tp24);
export const socialLogin = _tp24.data.social_login;
export const host = path(['data', 'config', 'host'], _tp24);
export const website = pathOr('', ['data', 'config', 'website'], _tp24);
export const locales = pathOr({}, ['data', 'locales'], _tp24);
export const localeFull = pathOr('el_GR', ['data', 'locale_full'], _tp24);
export const menuHeader = pathOr([], ['data', 'menu', 'header'], _tp24);
export const menuFooter = pathOr([], ['data', 'menu', 'footer'], _tp24);
export const bankDeposit = pathOr({}, ['data', 'paymentConfig', 'bankDeposit'], _tp24);
export const contact = pathOr({}, ['data', 'contact'], _tp24);
export const assetHost = pathOr({}, ['data', 'config', 'asset_host'], _tp24);
export const socialIcons = pathOr({}, ['data', 'social_icons'], _tp24);
export const appstoreUrl = pathOr('', ['data', 'native', 'appstoreUrl'], _tp24);
export const playstoreUrl = pathOr('', ['data', 'native', 'playstoreUrl'], _tp24);
export const alliances = pathOr({}, ['data', 'airlines', 'alliances'], _tp24);
export const airlines = pathOr({}, ['data', 'airlines', 'airlines'], _tp24);
export const seatClasses = pathOr({}, ['data', 'seatClasses'], _tp24);
export const market = pathOr('gr', ['data', 'config', 'market'], _tp24);
export const brandLocale = pathOr('el_GR', ['data', 'config', 'locale'], _tp24);
export const language = pathOr('el', ['data', 'language'], _tp24);
export const availableWings = pathOr([], ['data', 'availableWings'], _tp24);
export const multicurrency = pathOr({}, ['data', 'multicurrency'], _tp24);
export const currency = pathOr([], ['culture_data', 'numberFormat', 'currency'], _tp24);
export const isoCurrency = _tp24?.culture_data?.numberFormat?.currency?.iso_currency ?? 'USD';
export const dateFormat = pathOr('', ['culture_data', 'dateFormat', 'short'], _tp24);
export const numOfAdsHome = pathOr(0, ['data', 'advertisements', 'home'], _tp24);
export const isAirtickets = brand === 'airtickets';
export const isTravelplanet24 = brand === 'travelplanet24';
export const isFerryscanner = brand === 'ferryscanner';
export const isRTL = pathOr(false, ['data', 'isRTL'], _tp24);
export const isAffiliate = pathOr(false, ['data', 'white_label', 'options'], _tp24);
export const isNative = pathOr(false, ['data', 'isNative'], _tp24);
export const bookingData = pathOr({}, ['data', 'booking_data'], _tp24);
export const dialingCodes = pathOr({}, ['data', 'dialing_codes'], _tp24);
export const searchQuery = pathOr({}, ['data', 'search_query'], _tp24);
export const siteKey = pathOr('', ['data', 'site_key'], _tp24);
export const defaultDialingCode = pathOr('', ['data', 'default_dialing_code'], _tp24);
export const partner = pathOr({}, ['data', 'partner'], _tp24);
export const forcedChoice = pathOr(false, ['data', 'features', 'isForcedChoiceEnabled'], _tp24);
export const controller = pathOr('', ['controller'], _tp24);
export const creditCardsInfo = pathOr({}, ['data', 'creditCardsInfo'], _tp24);
export const isTabis = window.location.search.indexOf('tabis=true') > -1;
export const environment = pathOr('development', ['data', 'config', 'env'], _tp24);
export const isProduction = environment === 'production';
export const countries = pathOr({}, ['data', 'countries'], _tp24);
export const occupations = pathOr({}, ['data', 'occupations'], _tp24);
export const taxOffices = pathOr({}, ['data', 'taxOffices'], _tp24);
export const ferryCompanies = pathOr({}, ['data', 'ferryCompanies'], _tp24);
export const vehicles = pathOr({}, ['data', 'vehicles'], _tp24);
export const cms = pathOr({}, ['data', 'cms'], _tp24);
export const bookingBaseUrl = pathOr('ferry', ['data', 'booking_flow_url', localeFull, 'home'], _tp24);
export const contactForm = () => {
  const links = {airtickets: {tp: '/contact', uk: '/contact'}};
  return pathOr('', [brand, market], links);
};
export const ferriesDomain = () => {
  const domains = ['production', 'staging'].includes(environment) ?
    {
      airtickets: 'https://ferries.airtickets.com',
      travelplanet24: 'https://www.travelplanet24.com',
      ferryscanner: `https://web${environment === 'staging' ? 'stage' : ''}.ferryscanner.com`
    } :
    {
      airtickets: 'http://gr.airtickets.local',
      travelplanet24: 'http://gr.local',
      ferryscanner: 'http://ie.ferryscanner.local'
    };

  return domains[brand];
};
